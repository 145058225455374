import React from 'react'
import { cards } from './consts'

const ContentCard = () => (
  <div className='flex flex-col items-center md:mx-5 w-auto bg-black rounded-2xl'>
    <div className='text-3xl md:text-4xl font-medium text-white p-14'>
      Why Standard CPQ Tools Aren’t Enough
    </div>
    <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
      {cards.map((card, index) => (
        <div
          key={index}
          className='text-white p-5'
        >
          <div className='text-2xl md:text-3xl font-medium mb-5'>
            {card.title}
          </div>

          <p className='text-xl text-gray-300 md:p-6'>{card.desc}</p>
        </div>
      ))}
    </div>
  </div>
)

export default ContentCard
