export const challenges = [
  {
    title: 'The Hidden Cost of Manual Processes',
    desc: 'Many companies rely on outdated, manual workflows for product customization, quoting, and sales. This often leads to inefficiencies, errors, and slower response times, leaving customers frustrated and businesses at a disadvantage.',
    challenges: [
      'Time-consuming back-and-forth communication with customers.',
      'Frequent errors in quotes due to manual processes.',
      'Limited scalability as your business grows.'
    ],
    solutions: [
      'A custom configurator automates these processes, reducing errors and freeing up your team to focus on high-value tasks.',
      'Customers can configure products themselves, reducing response times and improving satisfaction.'
    ]
  },
  {
    title: 'Struggling to Keep Up with Customer Expectations?',
    desc: "Today's customers expect quick, seamless, and personalized buying experiences. Without a configurator, it’s challenging to meet these demands and stand out in a competitive market.",
    challenges: [
      'Customers want real-time customization options and visualizations.',
      'The inability to showcase product variations effectively can lead to lost sales.',
      'Competitors who offer configurators already have an edge.'
    ],
    solutions: [
      'A configurator allows customers to personalize and visualize products in real time.',
      'By providing a professional and modern experience, you gain a competitive edge and build trust with your customers.'
    ]
  },
  {
    title: 'Complex Products, Simple Solutions',
    desc: 'If your products have multiple variations, configurations, or options, explaining them manually can overwhelm both your team and your customers.',
    challenges: [
      'Sales teams struggle to explain complex product options without a visual tool.',
      'Customers feel confused and unsure, leading to lost opportunities.',
      'Scaling becomes difficult without a streamlined process.'
    ],
    solutions: [
      'A configurator simplifies even the most complex products by breaking them down into easy-to-understand options.',
      'Your sales team can rely on a powerful tool that eliminates confusion and boosts confidence for customers.'
    ]
  }
]

export const cards = [
  {
    title: 'Generic CPQ Solutions Don’t Understand Your Business',
    desc: 'Sure, CPQ tools handle pricing. But they don’t create experiences. They don’t make buying exciting. They don’t help your brand stand out.'
  },
  {
    title: 'Product Changes Shouldn’t Slow You Down',
    desc: 'Your products evolve. Your configurator should, too. With a fully adaptable system, you’ll never have to rely on developers for small updates again.'
  },
  {
    title: 'Powerful CPQ Meets Custom UX: The Best of Both Worlds',
    desc: 'We combine advanced configurator technology from leading CPQ platforms with our expertise in user experience and seamless integration. The result? A configurator that’s not only powerful—but feels effortless.'
  }
]
