import React, { useState } from 'react'
import ContentContainer from '../../common/Layout/ContentContainer'
import DesktopMenu from './DesktopMenu'
import Logo from './Logo'
import BurgerIcon from '../Icons/BurgerIcon'
import MobileNavigation from './MobileNavigation'
import PropTypes from 'prop-types'
import ContactForm from '../ContactForm/ContactForm'

const NavBar = ({ navActive, setNavActive, openForm }) => {
  const [isFormOpen, setIsFormOpen] = useState(false)

  const handleMobileClose = () => {
    setNavActive(false)
    setIsFormOpen(true)
  }

  return (
    <div className='h-full m-6'>
      <ContentContainer className='flex justify-between items-center w-full h-full z-30'>
        <Logo />
        <DesktopMenu openForm={openForm} />
        <BurgerIcon
          className='w-8 h-5 cursor-pointer lg:w-9 lg:h-6 xl:hidden'
          onClick={() => setNavActive(!navActive)}
          active={navActive}
        />
      </ContentContainer>
      <MobileNavigation
        open={navActive}
        onClick={handleMobileClose}
        onClose={() => setNavActive(false)}
      />
      <ContactForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
      />
    </div>
  )
}

export default NavBar

NavBar.propTypes = {
  navActive: PropTypes.bool,
  setNavActive: PropTypes.func,
  openForm: PropTypes.func
}

NavBar.defaultProps = {
  navActive: false
}
