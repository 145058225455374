import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import ColorsFilter from '../ColorsFilter'
import PropTypes from 'prop-types'
import FadeInUpAnimation from '../FadeInUpAnimation/FadeInUpAnimation'
import Quote from '../Icons/Quote'

const Testimonial = ({ testimonial }) => {
  const { clientReview, clientAvatar, clientName, clientPosition } = testimonial
  return (
    <div className='flex flex-col lg:flex-row lg:gap-16'>
      <FadeInUpAnimation>
        <Quote />
      </FadeInUpAnimation>
      <div className='my-0 h-full flex flex-col justify-between text-2xl leading-8 font-light lg:text-5xl lg:leading-[52px] max-md:mt-4'>
        <p className='mb-12'>{clientReview}</p>
        <div className='flex items-end'>
          <div className='h-full flex items-center'>
            <ColorsFilter className='inline-block mr-5 rounded-full z-0'>
              <GatsbyImage
                className='rounded-full w-20 h-20'
                image={clientAvatar?.childImageSharp?.gatsbyImageData}
                alt={`${clientName} avatar`}
                loading='lazy'
                imgClassName='rounded-full w-20'
              />
            </ColorsFilter>
            <div>
              <p>{clientName}</p>
              <p className='text-brand-primary'>{clientPosition}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial

Testimonial.propTypes = {
  testimonial: PropTypes.shape({
    clientReview: PropTypes.string.isRequired,
    clientAvatar: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object.isRequired
      }).isRequired
    }),
    clientName: PropTypes.string.isRequired,
    clientPosition: PropTypes.string.isRequired
  }).isRequired
}
