import React, { useState } from 'react'
import NavBar from '../../common/ConfiguratorNavBar/NavBar'
import PropTypes from 'prop-types'

const Header = ({ openForm }) => {
  const [navActive, setNavActive] = useState(false)

  return (
    <header className='absolute w-full h-20 z-10 transition duration-700'>
      <NavBar
        navActive={navActive}
        setNavActive={setNavActive}
        openForm={openForm}
      />
    </header>
  )
}

export default Header

Header.propTypes = {
  openForm: PropTypes.func
}
