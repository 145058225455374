import ContentContainer from '../../common/Layout/ContentContainer'
import SocialMedia from '../SocialMedia/SocialMedia'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { linksPropTypes } from '../propTypes'
import Link from '../TgcLink/ConfiguratorLink'
import BurgerIcon from '../Icons/BurgerIcon'

const navClassNames = (open) =>
  clsx(
    'fixed top-0 bottom-0 left-0 w-full bg-white overflow-hidden z-[10] xl:hidden',
    'transition-[max-height] duration-500',
    {
      'min-h-screen': open,
      'max-h-0': !open
    }
  )

const MobileNavigation = ({ open, onClose, onClick }) => {
  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [open])

  return (
    <nav className={navClassNames(open)}>
      <ContentContainer className='flex w-full h-full flex-col items-start justify-end relative'>
        <BurgerIcon
          className='w-8 h-5 cursor-pointer lg:w-9 lg:h-6 absolute right-6 top-6'
          onClick={onClose}
          active={open}
        />
        <div
          className={`
        flex flex-col mb-8 font-light text-5xl leading-none xs:mb-12 xs:text-6xl xs:leading-tight
        mt-20 xl:text-8xl
      `}
        >
          <Link onClick={onClick}>Contact Us</Link>
        </div>
        <div className='flex mb-5 xl:mb-20 items-center justify-between w-full'>
          <SocialMedia iconClassName='w-9 text-brand-primary lg:w-14' />
        </div>
      </ContentContainer>
    </nav>
  )
}

export default MobileNavigation

MobileNavigation.propTypes = {
  ...linksPropTypes,
  open: PropTypes.bool,
  onClose: PropTypes.func
}

MobileNavigation.defaultProp = {
  links: [],
  open: false
}
