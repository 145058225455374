import React from 'react'
import WhoWeAre from './WhoWeAre'
import Companies from './Companies/Companies'
import Testimonials from './Testimonials/Testimonials'
import TitleCard from './TitleCard/TitleCard'
import BottomBar from './TitleCard/BottomBar'
import HowItWorks from './HowItWorks/HowItWorks'
import Challenges from './Challenges/Challenges'
import ConfigureNow from './ConfigureNow'
import Configurator from './Configurators/Configurator'

const Home = () => (
  <section className='flex flex-col'>
    <TitleCard />
    <BottomBar />
    <WhoWeAre />
    <Companies />
    <div id='discover-solutions'>
      <Configurator
        src='https://palettierloesung.coboworx.com/e73c41f3-ee85-4908-bf6b-53b74f4ce2e5?step=1'
        name='Coboworx'
        description='An innovative start-up based in Germany, Coboworx designs standardised robotics solutions for SMEs. We created their 3D configurator, allowing users to see the model of their solution in real time. This allows Coboworx to create a complex offer for the customer automatically using their data. We developed and deployed the web app, integrate it with CMS and CRM systems and set up tracking.'
      />
    </div>
    <Challenges />
    <Testimonials />
    <div id='how-it-works'>
      <HowItWorks />
    </div>
    <ConfigureNow />
  </section>
)

export default Home
