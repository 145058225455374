import React from 'react'
import { Cursor, Typewriter } from 'react-simple-typewriter'
import Link from '../../../common/TgcLink/ConfiguratorLink'

const TextContent = () => (
  <div className='flex flex-col max-w-4xl'>
    <p className='md:mb-10 max-md:min-h-[170px] text-2xl font-normal leading-8 sm:text-3xl sm:leading-9 md:text-4xl md:leading-[58.5px] 2xl:text-[40px]'>
      Transform your product customization with our expert solution for custom
      <span>
        <Typewriter
          words={[' configurators']}
          loop={0}
          typeSpeed={100}
          deleteSpeed={50}
          delaySpeed={1000}
        />
        <Cursor
          cursorColor='#01DD93'
          cursorStyle='/'
        />
      </span>
    </p>

    <div className='flex flex-wrap gap-4'>
      <Link href='#discover-solutions'>Discover solutions</Link>
      <Link
        href='#how-it-works'
        variant='secondary'
      >
        How it works
      </Link>
    </div>
  </div>
)

export default TextContent
