import React from 'react'
import { LocalizedLink } from 'gatsby-theme-i18n'
import { StaticImage } from 'gatsby-plugin-image'

const Logo = () => (
  <LocalizedLink
    to='/'
    className='flex justify-center items-center'
  >
    <StaticImage
      className='w-[166px] lg:w-[212px]'
      src='../../../images/logo.png'
      loading='eager'
      alt='TheGoodCode logo'
      placeholder='blured'
      quality={90}
    />
  </LocalizedLink>
)

export default Logo
