import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Cursor, Typewriter } from 'react-simple-typewriter'
import Link from '../../common/TgcLink/ConfiguratorLink'
import ContactForm from '../../common/ContactForm/ContactForm'

const ConfigureNow = () => {
  const [isFormOpen, setIsFormOpen] = useState(false)

  return (
    <section className='relative h-screen w-full overflow-hidden'>
      <div className='absolute inset-0'>
        <StaticImage
          src='../../../images/home/configurenow.jpg'
          alt='Team collaboration'
          className='w-full h-full'
          placeholder='blurred'
          layout='fullWidth'
          objectPosition='center'
          objectFit='cover'
        />
        <div className='absolute inset-0 bg-[#004E34]/60'></div>
      </div>

      <div className='relative z-10 flex flex-col items-center justify-center min-h-screen text-white px-4'>
        <h1 className='mb-20'>
          Start to configure
          <span>
            <Typewriter
              words={[' now']}
              loop={0}
              typeSpeed={100}
              deleteSpeed={50}
              delaySpeed={1000}
            />
            <Cursor
              cursorColor='#01DD93'
              cursorStyle='/'
            />
          </span>
        </h1>

        <Link
          onClick={(e) => {
            e.preventDefault()
            setIsFormOpen(true)
          }}
        >
          Contact us
        </Link>
      </div>

      <ContactForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
      />
    </section>
  )
}

export default ConfigureNow
