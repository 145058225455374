import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './testimonialsCarousel.css'
import Testimonial from '../Testimonial'
import { testimonialsCarouselPropTypes } from '../../propTypes'
import SliderNavButton from '../SliderNavButton'

const TestimonialsCarousel = ({ className, data }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    nextArrow: <SliderNavButton />,
    prevArrow: <SliderNavButton reversed />
  }

  return (
    <Slider
      className={className}
      {...settings}
    >
      {data.allFile.edges.map((testimonial, idx) => {
        const { frontmatter } = testimonial.node.childMarkdownRemark
        return (
          <Testimonial
            key={idx}
            testimonial={frontmatter}
          />
        )
      })}
    </Slider>
  )
}

export default TestimonialsCarousel

TestimonialsCarousel.propTypes = testimonialsCarouselPropTypes
