import React from 'react'
import PropTypes from 'prop-types'
import TgcLink from '../../../common/TgcLink/TgcLink'
import { StaticImage } from 'gatsby-plugin-image'
import Link from '../../../common/TgcLink/Link'

const Configurator = ({ src, name, description }) => (
  <div className='flex flex-col border border-gray-200 rounded-2xl overflow-hidden m-10 shadow-md'>
    <div className='w-full aspect-video'>
      <div className='hidden md:block w-full h-full'>
        <iframe
          src={src}
          className='w-full h-full border-0'
          title={`${name} preview`}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
      <div className='block md:hidden relative'>
        <Link
          href='https://palettierloesung.coboworx.com/e73c41f3-ee85-4908-bf6b-53b74f4ce2e5?step=1'
          className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 font-normal rounded-full bg-brand-primary text-black hover:bg-black hover:text-white px-8 py-2'
        >
          Try it out
        </Link>
        <StaticImage
          src='../../../../images/configurator/coboworx-mobile.png'
          alt='a macbook desktop version'
          loading='eager'
          layout='fullWidth'
          placeholder='tracedSVG'
        />
      </div>
    </div>

    <div className='p-10 bg-white'>
      <h2 className='text-5xl font-light mb-3 text-gray-800'>{name}</h2>

      <div className='flex flex-col md:flex-row items-start justify-between gap-14'>
        <p className='text-xl font-light max-w-[60vw]'>{description}</p>
        <TgcLink
          href={src}
          target='_blank'
          rel='noopener noreferrer'
          variant='secondary'
          className='text-sm sm:text-base px-4 sm:px-6'
        >
          Discover solutions
        </TgcLink>
      </div>
    </div>
  </div>
)

Configurator.propTypes = {
  src: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default Configurator
