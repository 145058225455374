import TextWithDots from '../../common/TextWithDots/TextWithDots'
import React from 'react'
import ContentContainer from '../../common/Layout/ContentContainer'
import FadeInUpAnimation from '../../common/FadeInUpAnimation/FadeInUpAnimation'

const WhoWeAre = () => (
  <ContentContainer
    as='article'
    className='flex flex-col mt-12 md:mt-24 px-2 md:px-6'
  >
    <FadeInUpAnimation>
      <TextWithDots className='mb-8 md:mb-12 text-xl md:text-2xl lg:text-6xl'>
        your customers want more
      </TextWithDots>
    </FadeInUpAnimation>
    <div className='mt-8 md:mt-16 max-w-screen-xl mx-auto w-full'>
      <FadeInUpAnimation
        className='text-2xl font-light lg:text-4xl px-4'
        as='p'
      >
        Every day, customers visit your website. They scroll, they explore, they
        imagine—but they don’t buy. Why? Because they don’t see exactly what
        they want. They don’t know what’s possible. And they don’t have control.
        Instead, they send emails. They wait for quotes. And sometimes… they
        leave. But what if your customers could create their perfect product in
        real-time? No confusion. No friction. Just a seamless, intuitive
        experience.
      </FadeInUpAnimation>
    </div>
  </ContentContainer>
)

export default WhoWeAre
