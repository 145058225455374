import React from 'react'
import { linksPropTypes } from '../propTypes'
import PropTypes from 'prop-types'
import Link from '../TgcLink/ConfiguratorLink'

const DesktopMenu = ({ openForm }) => (
  <nav className='hidden xl:flex xl:items-center xl:gap-10'>
    <Link onClick={openForm}>Contact Us</Link>
  </nav>
)

export default DesktopMenu

DesktopMenu.propTypes = linksPropTypes

DesktopMenu.defaultProps = {
  openForm: PropTypes.func
}
