import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import XIcon from '../Icons/XIcon'

const ContactForm = ({ isOpen, onClose }) => {
  if (!isOpen) return null
  const firstNameRef = useRef(null)
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    if (isOpen) {
      if (firstNameRef.current) {
        firstNameRef.current.focus()
      }
    }
  }, [isOpen])

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitted(true)
  }

  return (
    <div
      className='fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center p-0 sm:p-4'
      onClick={handleOverlayClick}
    >
      <div className='bg-white rounded-lg shadow-xl w-full h-full sm:h-auto sm:w-[95%] md:w-3/4 lg:w-1/2 max-w-4xl m-0 sm:m-4 overflow-y-auto'>
        <div className='p-4 sm:p-6 lg:p-8'>
          <div className='flex justify-between items-center mb-4 sm:mb-6'>
            <h2 className='text-lg sm:text-xl md:text-2xl font-bold text-gray-800'>
              Contact Information
            </h2>
            <button
              onClick={onClose}
              className='text-gray-500 hover:text-gray-700 p-2'
              aria-label='Close'
            >
              <XIcon className='w-5 h-5 sm:w-6 sm:h-6' />
            </button>
          </div>

          {isSubmitted ? (
            <div className='text-center py-8'>
              <h3 className='text-2xl font-semibold text-gray-800 mb-4'>
                Thank you for reaching out.
              </h3>
              <p className='text-gray-600 mb-6'>
                We've received your request and will get back to you as soon as
                possible.
                <br />
                In the meantime, feel free to explore more about what we do.
              </p>
              <a
                href='/'
                className='text-xl md:text-2xl font-normal rounded-full px-8 py-2 bg-brand-primary text-black hover:text-white hover:bg-black inline-block'
              >
                Back to Homepage
              </a>
            </div>
          ) : (
            <form
              method='POST'
              action='/submit-form'
              className='space-y-4 sm:space-y-6'
              onSubmit={handleSubmit}
            >
              <div className='grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-4 md:gap-6'>
                <div>
                  <label
                    htmlFor='firstName'
                    className='block text-sm font-medium text-gray-700'
                  >
                    First Name <span className='text-red-500'>*</span>
                  </label>
                  <input
                    type='text'
                    id='firstName'
                    name='firstName'
                    required
                    className='mt-1 block w-full rounded-md border border-gray-300 shadow-sm'
                  />
                </div>

                <div>
                  <label
                    htmlFor='lastName'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Last Name <span className='text-red-500'>*</span>
                  </label>
                  <input
                    type='text'
                    id='lastName'
                    name='lastName'
                    required
                    className='mt-1 block w-full rounded-md border border-gray-300 shadow-sm'
                  />
                </div>

                <div className='md:col-span-2'>
                  <label
                    htmlFor='companyName'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Company Name <span className='text-red-500'>*</span>
                  </label>
                  <input
                    type='text'
                    id='companyName'
                    name='companyName'
                    required
                    className='mt-1 block w-full rounded-md border border-gray-300 shadow-sm'
                  />
                </div>

                <div>
                  <label
                    htmlFor='phoneNumber'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Phone Number
                  </label>
                  <input
                    type='tel'
                    id='phoneNumber'
                    name='phoneNumber'
                    className='mt-1 block w-full rounded-md border border-gray-300 shadow-sm'
                  />
                </div>

                <div>
                  <label
                    htmlFor='emailAddress'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Email Address
                  </label>
                  <input
                    type='email'
                    id='emailAddress'
                    name='emailAddress'
                    className='mt-1 block w-full rounded-md border border-gray-300 shadow-sm'
                  />
                </div>
              </div>

              <div className='text-center py-4'>
                <p className='text-gray-600 text-sm mb-6'>
                  Detailed information on the handling of user data can be found
                  in our{' '}
                  <a
                    href='/'
                    className='text-sm font-normal text-blue-400 hover:text-blue-600 hover:cursor-pointer'
                  >
                    privacy policy
                  </a>
                </p>
              </div>

              <div className='mt-4 sm:mt-6'>
                <button
                  type='submit'
                  className='text-xl md:text-2xl font-normal rounded-full px-8 py-2 bg-brand-primary text-black hover:text-white hover:bg-black'
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

ContactForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ContactForm
