import Home from '../components/en/Configurator/Home'
import React from 'react'
import Layout from '../components/en/Configurator/Layout/Layout'

const ConfiguratorPage = () => (
  <Layout
    title='Configurator | the.good.code; - Unlock the power of custom configurators'
    description='Automate product customization, enhance user experience, and scale your business effortlessly.'
  >
    <Home />
  </Layout>
)

export default ConfiguratorPage
