import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import clsx from 'clsx'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "logos" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(width: 300, formats: [AUTO, WEBP, AVIF], quality: 80)
        }
      }
    }
  }
`

const marqueeGroupClassNames = clsx(
  'shrink-0 flex justify-around items-center min-w-full space-x-20 animate-scroll-logos group-hover:pause'
)

const Companies = () => {
  const data = useStaticQuery(query)
  const companies = [
    {
      name: 'Coboworx',
      link: 'https://www.coboworx.com/',
      logo: data.allFile.nodes.find((node) => node.name === 'coboworx_config')
    },
    {
      name: 'Dewulf',
      link: 'https://www.dewulfgroup.com',
      logo: data.allFile.nodes.find((node) => node.name === 'dewulf')
    },
    {
      name: 'Elfsquad',
      link: 'https://www.elfsquad.io/',
      logo: data.allFile.nodes.find((node) => node.name === 'elfsquad_config')
    },
    {
      name: 'Coverseal',
      link: 'https://www.coverseal.com/',
      logo: data.allFile.nodes.find((node) => node.name === 'coverseal_config')
    },
    {
      name: 'OnLevel',
      link: 'https://www.onlevel.com/',
      logo: data.allFile.nodes.find((node) => node.name === 'onlevel_config')
    }
  ]

  return (
    <div className='lg:my-24 mb-16 flex overflow-hidden select-none h-48 group'>
      <ul className={marqueeGroupClassNames}>
        {companies.map((company, idx) => (
          <li
            key={idx}
            className='flex items-center h-full'
          >
            <div className='flex items-center justify-center h-full w-[300px]'>
              <a
                className='flex items-center justify-center h-full px-6'
                href={company.link}
                target='_blank'
                rel='noopener noreferrer'
              >
                <GatsbyImage
                  className='w-auto max-h-[160px]'
                  image={company.logo.childImageSharp.gatsbyImageData}
                  alt={company.name}
                  loading='lazy'
                  imgClassName='object-contain'
                />
              </a>
            </div>
          </li>
        ))}
      </ul>
      <ul className={marqueeGroupClassNames}>
        {companies.map((company, idx) => (
          <li
            key={idx}
            className='flex items-center h-full'
          >
            <div className='flex items-center justify-center h-full w-[300px]'>
              <a
                className='flex items-center justify-center h-full px-6'
                href={company.link}
                target='_blank'
                rel='noopener noreferrer'
              >
                <GatsbyImage
                  className='w-auto max-h-[160px]'
                  image={company.logo.childImageSharp.gatsbyImageData}
                  alt={company.name}
                  loading='lazy'
                  imgClassName='object-contain'
                />
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Companies
