import React from 'react'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation'
import PropTypes from 'prop-types'

const SingleChallenge = ({ challenge }) => {
  const { title, desc, challenges, solutions } = challenge
  return (
    <div className='flex flex-col gap-7 my-8 px-0'>
      <FadeInUpAnimation
        className='text-4xl leading-8 font-light lg:text-6xl lg:leading-snug'
        as='p'
      >
        {title}
      </FadeInUpAnimation>
      <FadeInUpAnimation
        className='text-2xl leading-8 font-light lg:text-3xl lg:leading-snug'
        as='p'
      >
        {desc}
      </FadeInUpAnimation>
      <div className='grid grid-cols-1 gap-5 mt-8'>
        <div className='grid grid-cols-1 gap-5'>
          <h3 className='text-3xl font-bold'>Challenges:</h3>
          <ul className='list-none px-0'>
            {challenges.map((challenge, index) => (
              <li
                className='text-2xl flex items-center gap-3 mb-5'
                key={index}
              >
                <svg
                  className='min-w-[12px] mr-2'
                  width='12'
                  height='12'
                  viewBox='0 0 12 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    cx='6'
                    cy='6'
                    r='6'
                    fill='#00DD93'
                  />
                </svg>
                {challenge}
              </li>
            ))}
          </ul>
        </div>
        <div className='grid grid-cols-1 gap-5'>
          <h3 className='text-3xl font-bold'>Our Solution:</h3>
          <ul className='list-none px-0'>
            {solutions.map((solution, index) => (
              <li
                className='text-2xl flex items-center gap-3 mb-5'
                key={index}
              >
                <svg
                  className='min-w-[12px] mr-2'
                  width='12'
                  height='12'
                  viewBox='0 0 12 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    cx='6'
                    cy='6'
                    r='6'
                    fill='#00DD93'
                  />
                </svg>
                {solution}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SingleChallenge

SingleChallenge.propTypes = {
  challenge: PropTypes.shape({
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    challenges: PropTypes.arrayOf(PropTypes.string).isRequired,
    solutions: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
}
