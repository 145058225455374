import React from 'react'
import PropTypes from 'prop-types'
import Footer from '../../Footer/Footer'
import CommonLayout from '../../../common/Layout/Layout'
import CommonHeader from '../../../common/ConfiguratorHeader/Header'
import CookieConsentBanner from './CookieConsentBanner/CookieConsentBanner'
import ContactForm from '../../../common/ContactForm/ContactForm'

const Layout = ({ children, title, description, content, mail }) => {
  const [isFormOpen, setIsFormOpen] = React.useState(false)

  const openForm = (e) => {
    e.preventDefault()
    setIsFormOpen(true)
  }

  return (
    <CommonLayout
      header={<CommonHeader openForm={openForm} />}
      contactForm={
        <ContactForm
          isOpen={isFormOpen}
          onClose={() => setIsFormOpen(false)}
        />
      }
      footer={
        <Footer
          id='main-footer'
          invertBgColor={true}
          content={content}
          mail={mail}
        />
      }
      cookies={<CookieConsentBanner />}
      title={title}
      description={description}
      configurator={true}
    >
      {children}
    </CommonLayout>
  )
}

export default Layout

Layout.propTypes = {
  indexPage: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  mail: PropTypes.string
}

Layout.defaultProps = {
  indexPage: false
}
