import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const containerClassNames = (className) =>
  clsx('relative overflow-hidden', className)

const ColorsFilter = ({ children, className, color }) => (
  <div className={containerClassNames(className)}>
    {children}
    <div
      className={`absolute top-0 left-0 w-full h-full mix-blend-multiply ${color} ${className}`}
    />
  </div>
)

export default ColorsFilter

ColorsFilter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string
}

ColorsFilter.defaultProps = {
  className: '',
  color: 'bg-brand-primary'
}
