import React from 'react'
import { challenges } from './consts.js'
import ContentContainer from '../../../common/Layout/ContentContainer.jsx'
import SingleChallenge from './SingleChallenge.jsx'
import ContentCard from './ContentCard.jsx'

const Challenges = () => (
  <ContentContainer
    as='article'
    className='mt-8 xl:grid xl:grid-cols-3 xl:gap-5'
  >
    <div className='xl:col-span-2 xl:col-start-2'>
      <SingleChallenge challenge={challenges[0]} />
    </div>
    <div className='xl:col-span-3'>
      <ContentCard />
    </div>
    <div className='xl:col-span-2 xl:col-start-2'>
      <SingleChallenge challenge={challenges[1]} />
      <SingleChallenge challenge={challenges[2]} />
    </div>
  </ContentContainer>
)

export default Challenges
