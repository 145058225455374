export const designPhases = [
  {
    id: '01',
    title: 'Client interview',
    description:
      "To gain a better understanding of the client's business goals, and expectations, we need to conduct initial interviews. The interview typically lasts for about 60 during which we have option to get to know your goals."
  },
  {
    id: '02',
    title: 'First projects',
    description:
      "Our team will use interview insights to create two designs for further discussions. We'll consider all relevant factors, including user needs, industry trends, and design best practices."
  },
  {
    id: '03',
    title: 'Feedback loops',
    description:
      "The final design includes design mockups for desktops, tablets, and phones, along with detailed instructions for developers to implement the design team's vision accurately."
  },
  {
    id: '04',
    title: 'Final projects',
    description:
      'To ensure client satisfaction, we offer two rounds of revisions to refine the project to meet your needs.'
  }
]

export const developmentPhases = [
  {
    id: '01',
    title: 'Technical assesment',
    description:
      'In this phase we learn about your existing technical solutions and environment in which will live configurator.'
  },
  {
    id: '02',
    title: 'Development',
    description:
      'Our developers build the configuration, coding and integrating features, ensuring alignment with project requirements. We update the client regularly with progress reports and demos to confirm expectations.'
  },
  {
    id: '03',
    title: 'Testing',
    description:
      'QA team chek if the solution is free of bugs, responsive and consistent across platforms. Additionally, we establish open communication channels with you to address concerns and take feedback.'
  },
  {
    id: '04',
    title: 'Optionals',
    description:
      'We also provide services as maintanance, CRM inntegration, Ad-hoc Consultancy or Project Managmenet. This step depends on invidual customer needs.'
  },
  {
    id: '05',
    title: 'You Own It, 100%',
    description:
      'No hidden fees. No subscriptions. The configurator is yours, forever.'
  }
]
