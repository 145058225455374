import React, { useState, useRef } from 'react'
import { items } from './data'

const BottomBar = () => {
  const [activeCard, setActiveCard] = useState(null)
  const itemRefs = useRef([])

  return (
    <div className='w-full bg-black p-4 hidden lg:block'>
      <div className='max-w-[1440px] mx-auto flex justify-between items-center min-h-[124px] relative '>
        {items.map((item, index) => (
          <div
            key={index}
            className='relative w-[20%]'
            onMouseEnter={() => setActiveCard(index)}
            onMouseLeave={() => setActiveCard(null)}
            ref={(el) => (itemRefs.current[index] = el)}
          >
            <div className='cursor-pointer transition-colors duration-300 px-4'>
              {item.title.map((part, partIndex) => (
                <span
                  key={partIndex}
                  className={`${
                    part.color === 'green' ? 'text-green-400' : 'text-gray-200'
                  } text-3xl`}
                >
                  {part.text}
                </span>
              ))}
            </div>

            {activeCard === index && (
              <div className='absolute z-10 top-[-80px] pt-[80px] left-0 w-full bg-black border border-black rounded-lg shadow-lg pb-4 px-4'>
                <div className='cursor-pointer mb-4'>
                  {item.title.map((part, partIndex) => (
                    <span
                      key={partIndex}
                      className={`${
                        part.color === 'green'
                          ? 'text-green-400'
                          : 'text-gray-200'
                      }`}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
                <div className='text-lg text-gray-200 pt-4'>
                  {item.description}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default BottomBar
