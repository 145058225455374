export const items = [
  {
    title: [
      { text: 'Cost', color: 'green' },
      { text: '-', color: 'gray' },
      { text: 'Effective', color: 'gray' }
    ],
    description:
      'Reduce overhead and manual work by automating complex configurations, saving both time and resources.'
  },
  {
    title: [
      { text: 'Ease of ', color: 'gray' },
      { text: 'Updates', color: 'green' },
      { text: ' and ', color: 'gray' },
      { text: 'Adaptability', color: 'green' }
    ],
    description:
      'Product changes in your EMS are automatically reflected in the custom frontend—no manual updates or developer intervention needed.'
  },
  {
    title: [
      { text: 'Superior ', color: 'gray' },
      { text: 'User', color: 'green' },
      { text: ' Experience ', color: 'green' },
      { text: '(UX)', color: 'gray' }
    ],
    description:
      'Deliver an intuitive, visually engaging experience that keeps customers engaged and confident in their choices.'
  },
  {
    title: [
      { text: 'Brand Alignment', color: 'gray' },
      { text: ' and ', color: 'gray' },
      { text: 'Customization', color: 'green' }
    ],
    description:
      'Your configurator should look and feel like your brand. We create tailored designs that seamlessly match your identity.'
  },
  {
    title: [
      { text: 'Tailored', color: 'green' },
      { text: ' Solutions', color: 'gray' }
    ],
    description:
      'One size doesn’t fit all. We build configurators that align with your specific industry needs and sales processes.'
  }
]
