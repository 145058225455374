import React, { useState } from 'react'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useLocation } from '@reach/router'
import TextWithDots from '../../../../common/TextWithDots/TextWithDots'
import ArrowRightIcon from '../../../../common/Icons/ArrowRightIcon'
import Modal from '../../../../common/Cookies/Modal'
import TgcLink from '../../../../common/TgcLink/TgcLink'
import UnderlineEffect from '../../../../common/UnderlineEffect'
import PrivacyPolicyModalContent from './PrivacyPolicyModalContent'

const CookieConsentBanner = () => {
  const location = useLocation()
  const [modalOpened, setModalOpened] = useState(false)

  return (
    <CookieConsent
      location='top'
      buttonText={<TgcLink className='text-sm md:text-2xl'>Agree</TgcLink>}
      enableDeclineButton
      declineButtonText={
        <TgcLink className='text-sm md:text-2xl'>Decline</TgcLink>
      }
      cookieName='tgc-CookieConsent'
      disableButtonStyles
      disableStyles
      overlay
      overlayClasses='fixed left-0 top-0 w-full h-full bg-black/30 z-20'
      containerClasses='fixed left-0  top-0 w-full flex flex-col flex-wrap bg-white text-black shadow-gray-500 shadow-md z-20'
      contentClasses='max-w-[1440px] mx-auto px-6 lg:px-12 mt-10 text-sm lg:text-lg lg:mx-auto'
      buttonWrapperClasses='w-full max-w-[1440px] px-6 lg:px-12 gap-5 mb-10 flex justify-end mt-4 mx-auto'
      onAccept={() => {
        Cookies.set('tgc-tagmanager', true, { expires: 365 })
        initializeAndTrack(location)
      }}
      onDecline={() => {
        Cookies.set('tgc-CookieConsent', false)
        Cookies.set('tgc-tagmanager', false)
      }}
    >
      <TextWithDots className='mb-12'>privacy notice</TextWithDots>
      <p>
        This website stores cookies on your computer. These cookies are used to
        collect information about your interaction with our website and to
        enable us to remember you. We use this information to improve your
        website experience and to create analytics and metrics about our
        visitors to this website and other media sites. You can find more
        information about the cookies we use in our privacy policy.
      </p>
      <p className='my-10'>
        If you decline, your information will not be collected when you visit
        this website. A single cookie is set on your browser to remember that
        you do not wish to be tracked.
      </p>
      <p className='w-fit cursor-pointer text-brand-primary'>
        <UnderlineEffect>
          <span onClick={() => setModalOpened(true)}>
            More information here
          </span>
          <ArrowRightIcon className='inline ml-2 text-brand-primary w-4' />
        </UnderlineEffect>
      </p>
      {modalOpened && (
        <Modal hideModal={() => setModalOpened(false)}>
          <PrivacyPolicyModalContent />
        </Modal>
      )}
    </CookieConsent>
  )
}

export default CookieConsentBanner
