import ContentContainer from '../../../common/Layout/ContentContainer'
import React from 'react'
import TestimonialsCarousel from '../../../common/ConfiguratorTestimonials/Carousel/TestimonialsCarousel'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation'
import { graphql, useStaticQuery } from 'gatsby'

export const query = graphql`
  {
    allFile(
      filter: {
        sourceInstanceName: { eq: "content" }
        relativeDirectory: { eq: "testimonials" }
        name: { regex: "/[.]en/" }
      }
      sort: { fields: [childMarkdownRemark___frontmatter___id], order: ASC }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              clientReview
              clientAvatar {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    height: 100
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
              clientName
              clientPosition
            }
          }
        }
      }
    }
  }
`

const Testimonials = () => {
  const data = useStaticQuery(query)

  return (
    <div className='article-padding bg-black text-white'>
      <ContentContainer
        as='article'
        className='xl:grid xl:grid-cols-3 xl:gap-5 min-h-fit'
      >
        <FadeInUpAnimation className='xl:col-span-3 mt-10'>
          <TestimonialsCarousel data={data} />
        </FadeInUpAnimation>
      </ContentContainer>
    </div>
  )
}

export default Testimonials
