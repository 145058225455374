import React from 'react'
import { isExternalLink } from './helper'
import { LocalizedLink } from 'gatsby-theme-i18n'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Link = ({ children, className, href, onClick, variant }) => {
  const externalLink = isExternalLink(href)

  const linkClassName = clsx(
    `text-xl md:text-2xl font-normal rounded-full px-8 py-2 `,
    {
      'bg-brand-primary text-black hover:text-white hover:bg-black':
        variant === 'primary',
      'text-white bg-black hover:bg-brand-primary hover:text-black':
        variant === 'secondary'
    },
    className
  )

  return externalLink ? (
    <a
      className={linkClassName}
      href={href}
      onClick={onClick}
      target='_blank'
      rel='noopener noreferrer'
    >
      {children}
    </a>
  ) : (
    <LocalizedLink
      className={linkClassName}
      to={href}
      onClick={onClick}
    >
      {children}
    </LocalizedLink>
  )
}

export default Link

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary'])
}

Link.defaultProps = {
  className: '',
  href: '/',
  onClick: () => null,
  variant: 'primary'
}
