import React from 'react'
import CareerStep from '../../../common/Careers/CareerStep.jsx'
import { designPhases, developmentPhases } from './consts.js'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation.jsx'
import TextWithDots from '../../../common/TextWithDots/TextWithDots.jsx'
import ContentContainer from '../../../common/Layout/ContentContainer.jsx'

const HowItWorks = () => (
  <ContentContainer className='grid md:grid-cols-3 article-margin md:gap-20'>
    <FadeInUpAnimation>
      <TextWithDots className='mb-12 xl:col-span-1'>how it works</TextWithDots>
    </FadeInUpAnimation>
    <div className='col-span-2'>
      <FadeInUpAnimation delay={0.3}>
        <p className='my-10 md:my-0 text-2xl leading-8 font-light lg:text-5xl lg:leading-snug'>
          Seamless for your team. Effortless for your customers.
        </p>
      </FadeInUpAnimation>
      <h2 className='mt-20'>Design Phase</h2>
      <div className='md:mt-32 mt-20'>
        {designPhases.map((phase) => (
          <CareerStep
            id={phase.id}
            title={phase.title}
            description={phase.description}
            key={phase.id}
          />
        ))}
      </div>
      <h2 className='mt-20'>Development Phase</h2>
      <div className='md:mt-32 mt-20'>
        {developmentPhases.map((phase) => (
          <CareerStep
            id={phase.id}
            title={phase.title}
            description={phase.description}
            key={phase.id}
          />
        ))}
      </div>
    </div>
  </ContentContainer>
)

export default HowItWorks
