import React from 'react'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation'
import { StaticImage } from 'gatsby-plugin-image'
import TextContent from './TextContent'
import ContentContainer from '../../../common/Layout/ContentContainer'

const TitleCard = () => (
  <main className='bg-[#D6E0BB] px-6 py-12'>
    <ContentContainer className='relative grid grid-cols-1 md:grid-cols-12 items-center h-full md:gap-12 lg:min-h-[80vh]'>
      <div className='md:absolute z-[1] w-full md:col-span-7 order-last md:order-first ml-6'>
        <TextContent />
      </div>

      <div className='relative w-full md:col-span-6 md:col-start-7'>
        <FadeInUpAnimation>
          <div className='hidden md:block'>
            <StaticImage
              src='../../../../images/home/hero-macbook.png'
              alt='a macbook desktop version'
              loading='eager'
              layout='fullWidth'
              placeholder='tracedSVG'
            />
          </div>
          <div className='block mt-10 md:hidden'>
            <StaticImage
              src='../../../../images/home/hero-ipad.png'
              alt='a macbook mobile version'
              loading='eager'
              layout='fullWidth'
              placeholder='tracedSVG'
            />
          </div>
        </FadeInUpAnimation>
      </div>
    </ContentContainer>
  </main>
)

export default TitleCard
